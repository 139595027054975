.buttonsContainer {
    z-index: 2;
    width: 22%;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    flex-shrink: 0;
    padding-right: 70px;
    /* position: absolute;
    top: 80px; */
}

.button {
    font-family: inherit;
    height: 30px;
    width: 30px;
    border-radius: 0;
    /*color: #656666*/
    vertical-align: middle;
    outline: none;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 218, 68);
    border-radius: 4px;
    border: none;
    transition: .4s;
}

.button:last-child {
    margin-left: 10px;
}


.button:hover {
    cursor: pointer;
    background-color: rgb(253, 240, 120);
}

.phoneAndButtons{
    display: flex;
    align-items: center;
}
@media screen and (max-width:900px) {
    .buttonsContainer {
        padding-right: 40px;
    }
}

@media screen and (max-width:700px) {
    .buttonsContainer {
        padding-right: 0;
    }
}

