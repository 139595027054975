
@font-face {
  font-family: NouvelR-Regular;
  src: url("./fonts/NouvelR-Regular.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NouvelR-Bold;
  src: url("./fonts/NouvelR-Bold.woff2") format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NouvelR-Light;
  src: url("./fonts/NouvelR-Light.woff2") format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Renault-bold";
  src: url("./fonts/RenaultLifeCyWebWeb-Bold.eot");
  /* IE9 */
  src: url("./fonts/RenaultLifeCyWebWeb-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/RenaultLifeCyWebWeb-Bold.woff") format("woff");
  /* Modern Browsers */
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Renault";
  src: url("./fonts/RenaultLifeCyWebWeb-Regular.eot");
  /* IE9 */
  src: url("./fonts/RenaultLifeCyWebWeb-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/RenaultLifeCyWebWeb-Regular.woff") format("woff");
  /* Modern Browsers */
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Renault-light";
  src: url("./fonts/RenaultLifeCyWebWeb-Light.eot");
  /* IE9 */
  src: url("./fonts/RenaultLifeCyWebWeb-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/RenaultLifeCyWebWeb-Light.woff") format("woff");
  /* Modern Browsers */
  font-style: normal;
  font-weight: 100;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "NouvelR-Regular", sans-serif;
  margin: 0px;
  padding: 0px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background: linear-gradient(to bottom, #000000a3, #979797a3);
  /* background: #000000a3; */
  font-family: "NouvelR-Regular", sans-serif;
  overflow-x: hidden;
  margin: auto;
}

.App {
  width: 80%;
  background-color: white;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 5%;
  max-width: 920px;
  border: 1px solid #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.233) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  /* row-gap: 40px; */
}

.App>div {
  margin-bottom: 40px;
  /*box-sizing: border-box;*/
}

button {
  font-size: 1.1rem;
}

.submitButton {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 135px;
  height: 40px;
  font-family: inherit;
  padding: 5px;
  border-radius: 0;
  /* border: 1px solid #d9d9d6; */
  outline: none;
  color: #000;
  background-color: rgb(255, 218, 68);
  border-radius: 4px;
  border: none;
  transition: .4s;
}

.submitButton:hover {
  cursor: pointer;
  color: #000;
  background-color: rgb(247, 235, 130);
}

@media screen and (max-width: 900px) {

  div,
  input,
  select,
  textarea,
  label,
  button {
    font-size: 1rem;
  }

}

@media screen and (max-width: 1000px) {
  .App {
    width: 90%;
    margin-top: 4%;
    margin-bottom: 5%;
  }
}

@media screen and (max-width: 885px) {
  .App {
    width: 93%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 500px) {
  .App {
    width: 100%;
    padding-left: 7%;
    padding-right: 7%;
    margin: 0;
  }
}

.spinner{
  display: flex;
  justify-content: center;
}


@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}

.spinner::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: black;
  border-radius: 50%;
  animation: spinner 1s ease-in-out infinite;
}

.spinner-wrapper{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s all ease;
}

.visibleFinalMessage{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: black 1px solid;
  padding: 15px;
  width: 100%;
  max-width: 350px;
  height: 150px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notVisibleFinalMessage{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: black 1px solid;
  padding: 15px;
  width: 100%;
  max-width: 350px;
  height: 150px;
  background: white;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  display: none;
}

.okayButton{
  margin: 0 auto;
  margin-top: 20px;
  width: 60px;
  height: 40px;
  font-family: inherit;
  padding: 5px;
  border-radius: 0;
  /* border: 1px solid #d9d9d6; */
  outline: none;
  color: #000;
  background-color: rgb(255, 218, 68);
  border-radius: 4px;
  border: none;
  transition: .4s;
}
/*
body{
  background:  linear-gradient(to right, #efdf00,#3e3e3e) ;
  font-family: "NouvelR-Regular", sans-serif;
  overflow-x: hidden;
}

@font-face {
  font-family: "Renault-bold";
  src: url("./fonts/RenaultLifeCyWebWeb-Bold.eot");
  !* IE9 *!
  src: url("./fonts/RenaultLifeCyWebWeb-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/RenaultLifeCyWebWeb-Bold.woff") format("woff");
  !* Modern Browsers *!
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Renault";
  src: url("./fonts/RenaultLifeCyWebWeb-Regular.eot");
  !* IE9 *!
  src: url("./fonts/RenaultLifeCyWebWeb-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/RenaultLifeCyWebWeb-Regular.woff") format("woff");
  !* Modern Browsers *!
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Renault-light";
  src: url("./fonts/RenaultLifeCyWebWeb-Light.eot");
  !* IE9 *!
  src: url("./fonts/RenaultLifeCyWebWeb-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/RenaultLifeCyWebWeb-Light.woff") format("woff");
  !* Modern Browsers *!
  font-style: normal;
  font-weight: 100;
}


.App {
  !*text-align: center;*!
  width: 80%;

  background-color: white;

}

.submitButton{
  width: auto;
  font-family: "Renault", sans-serif;
  padding: 5px;
  height: 2rem;
  border-radius: 0;
  !* color: #656666; *!
  border: 1px solid #c3c3c3;
  background: white;
  outline: none;
  !*font-size: 1.4rem;*!
  !*display: flex;*!
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}


@media screen and (min-aspect-ratio: 13/9) {

  .App {
    !*text-align: center;*!
    width: 80%;
    !*position: absolute;*!
    !*left: 50%;*!
    !*transform: translateX(-50%);*!
    background-color: white;

  }
}

@media screen and (max-aspect-ratio: 13/9) { !* вертикальная*!
  .App{
    width: 100%;
    !*position: absolute;*!
    !*left: 50%;*!
    !*transform: translateX(-50%);*!
    background-color: white;
    overflow-x: hidden;
  }
}

@font-face {
  font-family: NouvelR-Regular;
  src: url("./fonts/NouvelR-Regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
}

*/
