.mainDataContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mainData {
    /* width: 58%; */
    flex-grow: .4;
    margin-top: 5px;
}

.photoContainer {
    margin: auto;
    margin-top: 23px;
    width: 32%;
    /* margin-left: 3%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
}

.image {
    width: 90%;
    height: auto;
    outline: none;
    border-radius: 4px;
}

@media screen and (max-width: 1000px) {
    .photoContainer {
        margin-left: 6%;
    }
}

/* load photo - button to upload applicant's photo */

button {
    font-size: 1.1rem;
}

.loadPhoto {
    margin: 20px;
    width: 150px;
    height: 40px;
    font-family: inherit;
    padding: 5px;
    outline: none;
    color: #000;
    background-color: rgb(255, 218, 68);
    border-radius: 4px;
    border: none;
    transition: .4s;
}

.loadPhoto:hover {
    cursor: pointer;
    color: #000;
    background-color: rgb(252, 229, 137);
}

.fileInput {
    display: none;
}

.fileInput {
    display: none;
}

.imageInputDescription {
    text-align: center;
    width: 200px;
    line-height: 25px;
}

@media screen and (max-width: 885px) {
    .mainDataContainer>div:first-child {
        margin-bottom: 0;
    }

    .mainData {
        /* width: 90%; */
        margin: auto;
        margin-top: 0;
        flex-grow: 1;
    }

    .photoContainer {
        width: 90%;
        margin: 25px auto;
    }

    .image {
        max-width: 200px;
    }
}