.inputAndSelect {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.inputAndSelect>div:first-child {
    margin-top: 0;
    margin-bottom: 0;
}
.buttonsContainer {
    z-index: 2;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* column-gap: 10px; */
}

.buttonsDescription {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
    margin-right: 10px;
}

.button {
    font-family: inherit;
    height: 30px;
    width: 30px;
    border-radius: 0;
    /*color: #656666*/
    vertical-align: middle;
    outline: none;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 218, 68);
    border-radius: 4px;
    border: none;
    transition: .4s;
}

.button:nth-child(3) {
    margin-left: 10px;
}


.button:hover {
    cursor: pointer;
    background-color: rgb(253, 240, 120);
}