/* container for both label and select */
.selectContainer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.label {
    display: block;
    /* justify-content: right; */
    vertical-align: middle;
    text-align: right;
    line-height: 40px;
    /* flex-basis: 150px; */
    max-width: 150px;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: inherit;
}

select {
    font-size: 1.1rem;
}

.select {
    height: 40px;
    color: #000;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='38' viewBox='0 0 24 24' width='28' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    margin-left: 15px;
    padding-left: 9px;
    /* flex-basis: 350px; */
    max-width: 350px;
    flex-shrink: 1;
    flex-grow: 1;
}


.select:focus {
    color: #000;
    border: 1px solid #b1b1b1;
    outline: none;
}

@media screen and (max-width:560px) {
    .selectContainer {
        flex-wrap: wrap;
        margin-top: -10px;
        margin-bottom: 20px;
        height: auto;
        row-gap: 5px;
    }

    .label {
        text-align: left;
        max-width: 350px;
        width: 100%;
        /* margin-bottom: 10px; */
    }

    .select {
        width: 100%;
        margin-left: 0;
    }
}

