
.emptyCircle{
    width: 1.6em;
    height:1.6em;
    background: transparent;
    border-radius: 50%;
    pointer-events: none;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    outline: none;
}

.filledCircle{
    width: 1.6em;
    height: 1.6em;
    background: #ffcc33;
    border-radius: 50%;
    /*border: 1px solid black;*/
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    outline: none;
}
.info{

    display: flex;
    /*justify-content: center;*/
    align-items: center;
    margin-bottom: 25px;
    user-select: none;
    /*padding-right: 300px;*/
    text-align: right;
}
@media screen and (min-aspect-ratio: 13/9) {  /* горизонтальная*/
    .container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        margin-left: 15px;
        height: 15px; user-select: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }


    .circle{
        font-size: 1.5rem;
        font-weight: 300;
        margin: 3px;
        padding: 0;
        user-select: none;
    }

}

@media screen and (max-aspect-ratio: 13/9) {  /* вертикальная*/
    .container{
        display: flex;
        width: 100%;
        left: 0;
        flex-direction: row;
        /* align-self: unset; */
        align-items: center;
        margin: 0;
        padding: 0;
        /* margin-left: 25px; */
        height: 15px;
        flex-grow: 1;
        user-select: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }


    .circle{
        font-size: 1.5rem;
        font-weight: 300;
        margin: 2px;
        padding: 0;
        user-select: none;
    }

}

@media screen and (max-width:400px) {

    .info{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        user-select: none;
        padding-right: 250px;
    }
}

.container:focus{
    outline: none;
}
.container{
    outline: none;
}

.mainContainer{
    outline: none;
}