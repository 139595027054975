














.inputContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin-top: 15px;
    font-size: 1.1rem;
    margin-bottom: 15px;
    height: 44px;
}

input,
input:focus,
input:not(focus){
    border: 0;
    border-bottom: 1px solid #b1b1b1;
    font-family: "NouvelR-Regular", sans-serif;
    outline: 0;
    color: #000;
    padding: 7px;
    background: transparent;
    transition: .3s;
    flex-shrink: 1;
    flex-grow: 1;
}

input:focus {
    /* border-color: #5f5e5e; */
    transition: .7s;
    font-family: "NouvelR-Regular", sans-serif;
    /* color: #3a3a3a; */
}

input::placeholder {
    color: #817f7f;
    font-family: "NouvelR-Light", sans-serif;
}

.rightBlock {
    font-family: "NouvelR-Light", sans-serif;
    margin-left: 15px;
    border: none;
    display: flex;
    flex-grow: 1;
    max-width: 350px;
}

.label {
    text-align: right;
    flex-grow: 1;
}

/* .input {
    flex-basis: 350px;
    max-width: 350px;
    border-radius: 0;
    border: 1px solid #d9d9d6;
    outline: none;
    font-size: 1.1rem;
} */

input {
    flex-basis: 350px;
    max-width: 350px;
    font-family: inherit;
    border-radius: 0;
    outline: none;
    font-size: 1.1rem;
}

.label {
    display: inline-block;
    flex-basis: 150px;
    max-width: 150px;
    vertical-align: bottom;
    align-self: center;
    word-wrap: break-word;
}

@media screen and (max-width:560px) {
    .inputContainer {
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 22px;
        height: auto;
        /* row-gap: 5px; */
    }

    .rightBlock {
        margin-left: 0;
    }

    .label {
        text-align: left;
        max-width: 350px;
        flex-basis: 200px;
        margin-bottom: 5px;
    }

    input {
        width: 300px;
        max-width: 100%;
    }

    input,
    input:focus,
    input:not(:focus) {
        padding-left: 0;
    }
}

@media screen and (max-width:433px) {
    input {
        width: auto;
    }
}

.inputError{

    border-bottom: 1px solid red !important;
}







