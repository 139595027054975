textarea::placeholder {
    font-family: "NouvelR-Light", sans-serif;
    color: #817f7f;
}

.container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    flex-grow: 1;
}

.label {
    display: inline-block;
    text-align: right;
    flex-basis: 150px;
    width: 150px;
    font-family: inherit;
}

.textAreaWrapper {
    max-width: 350px;
    flex-basis: 350px;
    margin-left: 15px;
}

textarea {
    font-size: 1.1rem;
    border: 1px solid #b1b1b1;
    border-radius: 3px;
    height: 120px;
    max-height: 220px;
    resize: vertical;
    overflow: auto;
    outline: none;
    padding: 10px 8px;
    width: 100%;
    box-shadow: none;
}

textarea:focus {
    height: 120px;
    /* resize: none; */
    border: 1px solid #b1b1b1;
    border-radius: 3px;
    outline: none;
    padding: 10px 8px;
    width: 100%;
    font-family: "NouvelR-Regular", sans-serif;
    box-shadow: none;
}

@media screen and (max-width: 900px) {
    textarea {
        font-size: 1rem;
    }
}

.inputError {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 120px;
    min-height: 120px;
    max-height: 220px;
    /* resize: none; */
    padding: 10px 8px;
    font-family: inherit;
    /*transition: .8s;*/
    outline: none;
    /* font-size: 1.1rem; */
    min-height: 120px;
}

.lengthStatus {
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
    margin-right: 5px;
}

@media screen and (max-width:680px) {
    .container {
        flex-wrap: wrap;
        /* row-gap: 20px; */
        margin-top: 20px;
    }

    .inputError {
        height: 120px;
    }

    .label {
        max-width: 100%;
        text-align: left;
        flex-basis: 350px;
        margin-bottom: 20px;
    }

    .textAreaWrapper {
        margin-left: 0;
    }
}

textarea.inputError{
     border: 1px solid red;
 }