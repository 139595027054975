.languageSkills {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-bottom: 35px;
    flex-wrap: wrap;
}

.languageSkills > div:nth-child(3) {
    /*flex-grow: 0.5;*/
    /*justify-content: start;*/

    width: 100%;
    box-sizing: border-box;
    user-select: none;
    /*don't display scale buttons for language */
}

.buttonsContainer {
    z-index: 2;
    width: 22%;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    flex-shrink: 0;
    padding-right: 70px;
    /* position: absolute;
    top: 80px; */
}

.buttonsContainer > div {
    width: 160px;
}

.button {
    font-family: inherit;
    height: 30px;
    width: 30px;
    border-radius: 0;
    /*color: #656666*/
    vertical-align: middle;
    outline: none;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 218, 68);
    border-radius: 4px;
    border: none;
    transition: .4s;
}

.button:nth-child(2) {
    margin-left: 10px;
}


.button:hover {
    cursor: pointer;
    background-color: rgb(253, 240, 120);
}

@media screen and (max-width: 900px) {
    .buttonsContainer {
        padding-right: 40px;
    }
}

@media screen and (max-width: 700px) {
    .buttonsContainer {
        padding-right: 0;
    }
}

@media screen and (max-width: 560px) {
    .languageSkills > div:first-child {
        margin-top: 0;
    }
}

@media screen and (max-width: 400px) {
    .languageSkills > div:first-child {
        max-width: 250px;
    }

    .buttonsContainer {
        margin-left: 10px;
    }

    .button:nth-child(2) {
        margin-left: 7px;
    }
}