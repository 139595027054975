.headerContainer {
    width: 80%;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 5px;
    background-image: linear-gradient(to top, white 0 46%, rgb(255, 218, 68) 46% 54%, white 53%);
    display: flex;
    justify-content: center;
}

.header {
    font-family: "NouvelR-Bold", sans-serif;
    display: inline-block;
    margin: 18px auto;
    padding: 0 12px;
    font-size: 27px;
    line-height: 33px;
    vertical-align: middle;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    background-color: white;
    flex-shrink: 1;
}

@media screen and (max-width: 1000px) {
    .headerContainer {
        width: 82%;
        margin-top: 20px;
    }
}

@media screen and (max-width: 900px) {
    .headerContainer {
        width: 100%;
    }

    .header {
        font-size: 23px;
        margin: 12px auto;
    }
}

@media screen and (max-width: 660px) {
    .header {
        font-size: 21px;
    }
}

@media screen and (max-width: 560px) {
    .headerContainer {
        background-image: none;
    }

    .header {
        font-size: 20px;
        padding: 0;
    }

    .header:before {
        content: "";
        position: absolute;
        top: -31%;
        left: -22px;
        width: 34px;
        height: 24px;
        border-top: 3px solid rgb(255, 218, 68);
        border-left: 3px solid rgb(255, 218, 68);
    }

    .header:after {
        content: "";
        position: absolute;
        bottom: -31%;
        right: -22px;
        width: 33px;
        height: 23px;
        border-bottom: 3px solid rgb(255, 218, 68);
        border-right: 3px solid rgb(255, 218, 68);
    }
}

@media screen and (max-width: 385px) {
    .header {
        font-size: 19px;
    }
}