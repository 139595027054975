.container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 15px;
}

div {
    font-size: 1.1rem;
}

.container div {
    /* Styles for a checkbox label */
    display: block;
    text-align: right;
    vertical-align: middle;
    flex-basis: 150px;
    max-width: 150px;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: inherit;
}

.checkbox+label {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;

}

.container .checkbox+label {
    margin-left: 15px;
    justify-content: start;
    max-width: 350px;
}

.description {
    font-size: 1.1rem;
}

.containerWithoutLabel {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.1rem;
    font-family: inherit;
    padding-left: 10px;
    flex-grow: 1;
}

/* Styles for a checkbox box */

.checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

label {
    font-size: 1.1rem;
}

.checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none
}

.checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.checkbox:checked+label::before {
    border-color: #fc3;
    border-radius: 4px;
    background-color: transparent;
    background-image: url(resources/checkMark.svg);
    /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");*/
}

@media screen and (max-width:560px) {
    .container .checkbox+label {
        max-width: 150px;
    }

    .checkbox+label {
        justify-content: start;
        max-width: 350px;
    }

    .containerWithoutLabel {
        padding-left: 0;
        justify-content: center;
    }

    .container div {
        flex-basis: 100px;
    }
}